import { cloneDeep } from "lodash";
import React, { createContext, useReducer } from "react";

const initialState = {};
const UPDATE = "UPDATE";

const reducer = (
  state = initialState,
  action: { type: string; payload: any }
) => {
  if (action.type === UPDATE) {
    const newState = cloneDeep(state);

    return newState;
  }

  return state;
};

export const CourseContext: React.Context<any> = createContext({
  courses: initialState,
  updateCourse: () => {},
});

export const CourseProvider = ({ children }: { children: any }) => {
  const [courses, dispatch] = useReducer(reducer, initialState);

  async function updateCourse(course: any) {
    console.log({ course });
    // update selected lesson and module
    // update completed modules

    dispatch({
      type: UPDATE,
      payload: courses,
    });
  }

  return (
    <CourseContext.Provider value={{ courses, updateCourse }}>
      {children}
    </CourseContext.Provider>
  );
};
