import React, { useContext, useState } from "react";
import { graphql, Link, navigate } from "gatsby";
// import { navigate } from "@reach/router";

import { LayoutMain } from "../layouts/layout-main/layout-main";
import { ModuleLesson, LessonNav } from "../components";
import { normalizeModule } from "../utils/utils";
import { ModuleRaw, Lesson } from "../utils/interfaces";
import { CourseContext } from "../context/CourseContext";

export default function Template({
  data,
  pageContext,
}: {
  data: {
    module: ModuleRaw;
    course: { frontmatter: { title: string; slug: string } };
  };
  pageContext: { lessons: Lesson[] };
}) {
  const { module, course } = data;
  const { lessons } = pageContext;
  const currentModule = normalizeModule(module);

  const [current, setCurrent] = useState({ lesson: 0 });
  const [currentLessons, updateLessons] = useState(lessons);

  const currentLesson: Lesson = currentLessons[current.lesson];
  const featuredImage =
    currentLesson.banner?.childImageSharp?.gatsbyImageData ||
    currentModule.banner?.childImageSharp?.gatsbyImageData ||
    null;
  const { updateCourse } = useContext(CourseContext);

  const handleSetCurrent = (payload: any) => {
    if (typeof payload.lesson !== undefined) {
      setCurrent(payload);
      window.scrollTo(0, 0);
    }
  };

  const handleComplete = (status: boolean, slug: string) => {
    // TODO: set in localStorage
    const updated = [...currentLessons];
    const toUpdate = currentLessons.find((lesson) => lesson.slug === slug);

    if (toUpdate) {
      toUpdate.complete = status;
      updateLessons(updated);
      updateCourse(course);
    }
  };

  const handlePagerClick = (direction: "prev" | "next") => {
    // *: need to check to see if it's the prev or next module
    const numLessons = lessons.length;
    const prevLesson = current.lesson - 1 > 0 ? current.lesson - 1 : null;
    const nextLesson =
      current.lesson + 1 > numLessons ? current.lesson + 1 : null;
    const newLesson = direction === "prev" ? prevLesson : nextLesson;
    window.scrollTo(0, 0);
    console.log({ currentLesson }, direction);

    if (newLesson) {
      setCurrent({ lesson: newLesson });
      return;
      // TODO: set url
    } else if (direction === "prev") {
      // *: go to prev module
      const prev = `/module/${currentLesson.parentCourse}${currentLesson.prevModule?.url}`;
      console.log("prev module", prev);
      // window.location.href = prev;
      navigate(prev);
      return;
    } else {
      // *: go to next module
      const next = `/module/${currentLesson.parentCourse}${currentLesson.nextModule?.url}`;
      console.log("next module", next);
      navigate(next);
      return;
    }
  };

  return (
    <LayoutMain
      isBlogPost={false}
      frontmatter={module.frontmatter}
      title={module.frontmatter.title}
      metaTitle={module.frontmatter.metaTitle}
    >
      <div className="course-holder">
        <p>
          <Link to={`/course/${course.frontmatter.slug}`}>
            {course.frontmatter.title}
          </Link>{" "}
          / {currentModule.title} / {currentLesson.title}
        </p>
        {currentLessons.length ? (
          <div className="elr-flex">
            <ModuleLesson
              onPagerClick={handlePagerClick}
              lesson={currentLesson}
              lessonImage={featuredImage}
              markComplete={handleComplete}
              moduleName={currentModule.title}
            />
            <div className="elr-position-relative elr-flex-1">
              <LessonNav
                lessons={currentLessons}
                onSetCurrent={handleSetCurrent}
                current={current.lesson}
              />
            </div>
          </div>
        ) : (
          <p>No Lessons</p>
        )}
      </div>
    </LayoutMain>
  );
}

export const ModuleBySlug = graphql`
  query ($slug: String!, $parentCourse: String!) {
    module: mdx(
      frontmatter: { slug: { eq: $slug }, parentCourse: { eq: $parentCourse } }
    ) {
      id
      body
      frontmatter {
        title
        metaTitle
        slug
        parentCourse
        description
        category
        categorySlug
        author
        date
        keywords
        order
        banner {
          id
          childImageSharp {
            id
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
          }
        }
      }
    }
    course: mdx(frontmatter: { slug: { eq: $parentCourse } }) {
      body
      frontmatter {
        title
        slug
      }
    }
  }
`;
